import { useCallback } from "react"
import { useCore } from "@hooks/useCore"
import { useShopify } from "@hooks/useShopify"
import { useKlaviyo } from "@hooks/useKlaviyo"
import { useCheckoutContext } from "@providers/checkout"
import { useTrackingContext } from "@providers/tracking"
import { useCustomerContext } from "@providers/customer"

export const useAnalytics = () => {
  const { tracked, trackedLists, setTrackedLists, gtm, latestVersion, colourOptionName } = useTrackingContext()
  const {
    helpers: { isDev, decodeShopifyId, formatPrice, capitalise },
  } = useCore()
  // const { activeVariant } = useAppContext() // TODO: Look to switch to this instead of passing variants
  const { checkout } = useCheckoutContext()
  const { customer } = useCustomerContext()
  const { track, trackProduct } = useKlaviyo()
  const { productNormaliser } = useShopify()

  const decorateUrl = (url: string): string => {
    if (typeof window !== "undefined") {
      // @ts-ignore next-line
      const ga = window[window["GoogleAnalyticsObject"]]
      // @ts-ignore next-line
      if (ga && typeof ga.getAll === "function") {
        // @ts-ignore next-line
        const tracker = ga.getAll()[0]
        // @ts-ignore next-line
        url = new window.gaplugins.Linker(tracker).decorate(url)
      }
      return url
    } else {
      return url
    }
  }

  const getVariantOptionValue = (options: any, selectedName: string) =>
    options?.find(({ name }: { name: string }) => name?.toLowerCase() === selectedName?.toLowerCase())?.value

  const pushDataLayer = useCallback(
    (dataLayer: any) => {
      if (gtm) {
        isDev ? console.log({ ...dataLayer }) : gtm.dataLayer({ dataLayer })
      }
    },
    [gtm, isDev]
  )

  const trackPageView = useCallback(() => {
    if (tracked) {
      setTimeout(() => {
        const dataLayer = latestVersion
          ? {
              event: "page_view",
              page_title: document?.title,
              page_path: `${document?.location?.pathname}${document?.location?.search || ""}`,
              path_location: `${document?.location?.protocol}//${document?.location?.hostname}${document?.location?.pathname}${document?.location?.search}`,
              ...(customer && { user_login_status: "Logged In" }),
              ...(customer?.id && { customer_id: customer?.id }),
            }
          : {
              event: "Pageview",
              pagePath: `${document?.location?.pathname}${document?.location?.search ? document.location.search : ""}`,
              pageTitle: document?.title,
              originalLocation: `${document?.location?.protocol}//${document?.location?.hostname}${document?.location?.pathname}${document?.location?.search}`,
            }
        pushDataLayer(dataLayer)
      }, 400)
    }
  }, [tracked, customer, latestVersion, pushDataLayer])

  const trackProductListView = useCallback(
    async (products, listName, searchTerm = null) => {
      if (trackedLists[listName]) {
        return
      }
      setTrackedLists(prevState => ({
        ...prevState,
        [listName]: true,
      }))
      pushDataLayer({
        event: searchTerm ? "view_search_results" : "view_item_list",
        ...(searchTerm && { search_term: searchTerm }),
        item_list_id: listName,
        item_list_name: listName,
        ecommerce: {
          items: products?.map(p => ({
            item_id: decodeShopifyId(p?.storefrontId || p?.id, "P roduct"),
            item_name: p?.title,
            item_category: p?.product_type || p?.tags?.find(tag => tag.includes("product_group")).split(":")[1],
            currency: checkout?.currencyCode || "AUD",
            item_brand: p?.vendor,
            item_variant: (p?.variants?.[0]?.option1 || getVariantOptionValue(p?.variants?.[0]?.selectedOptions, "Colour"))?.toUpperCase(),
            item_list_name: listName,
            quantity: 1,
            price: p?.price_min || p?.variants?.[0]?.price?.amount,
          })),
        },
      })
    },
    [checkout, trackedLists, setTrackedLists, pushDataLayer, decodeShopifyId]
  )

  const trackProductImpression = useCallback(
    async (product, index, list = null) => {
      const { collections, id, title, vendor, priceRange, productType } = productNormaliser(product)
      if (title) {
        const dataLayer = latestVersion
          ? {
              event: "view_item_list",
              ecommerce: {
                currency: checkout?.currencyCode || "AUD",
                item_list_name: list || "Collection Results", // Product Page, Collection Results, Instant Search, Search Results, Featured Products, Related Products, Cart
                item_list_id: "", // TODO: Define lists
                items: [
                  {
                    item_id: decodeShopifyId(id, "Product"),
                    item_name: title,
                    item_brand: vendor,
                    item_variant: getVariantOptionValue(product?.variants?.[0]?.selectedOptions, colourOptionName),
                    item_category: productType ?? collections?.[0]?.title,
                    index,
                    quantity: 1,
                    price: formatPrice(priceRange?.minVariantPrice?.amount),
                  },
                ],
              },
            }
          : {
              event: "productImpression",
              ecommerce: {
                currency: checkout?.currencyCode || "AUD",
                impressions: [
                  {
                    id: id,
                    name: title,
                    brand: vendor,
                    category: collections?.[0]?.title || productType,
                    price: priceRange?.minVariantPrice?.amount,
                    list: list || "Collection", // Collection Results, Instant Search, Search Results, Featured Products, Related Products, Wishlist
                    position: index,
                  },
                ],
              },
            }
        pushDataLayer(dataLayer)
      }
    },
    [checkout, productNormaliser, decodeShopifyId, formatPrice, latestVersion, colourOptionName, pushDataLayer]
  )

  const trackGDPRConsent = useCallback(() => {
    if (tracked) {
      const dataLayer = latestVersion
        ? {
            event: "gdpr_consent",
          }
        : {
            event: "gdpr_consent_decline",
          }
      pushDataLayer(dataLayer)
    }
  }, [tracked, latestVersion, pushDataLayer])

  const trackProductClick = useCallback(
    async (product, variant, index, listId = null, listName = null) => {
      const { collections, id, priceRange, title, vendor, productType } = product
      if (title) {
        const dataLayer = latestVersion
          ? {
              event: "select_item",
              ecommerce: {
                currency: checkout?.currencyCode || "AUD",
                items: [
                  {
                    item_id: decodeShopifyId(id, "Product"),
                    item_name: title,
                    item_brand: vendor,
                    item_variant: getVariantOptionValue(
                      variant?.selectedOptions || (product?.variants && product?.variants?.[0]?.selectedOptions),
                      colourOptionName
                    ),
                    item_category: productType ?? collections?.[0]?.title,
                    item_list_name: listName || "Collection Results", // Product Page, Collection Results, Instant Search, Search Results, Featured Products, Related Products, Cart
                    item_list_id: listId, // TODO: Define lists
                    index,
                    quantity: 1,
                    price: formatPrice(priceRange?.minVariantPrice?.amount),
                  },
                ],
              },
            }
          : {
              event: "productClick",
              ecommerce: {
                currency: checkout?.currencyCode || "AUD",
                click: {
                  actionField: { list: list || "Collection" }, // Collection Results, Instant Search, Search Results, Featured Products, Related Products, Wishlist
                  products: [
                    {
                      id: id,
                      name: title,
                      brand: vendor,
                      category: collections?.[0]?.title || productType,
                      price: priceRange?.minVariantPrice?.amount,
                      position: index,
                      variant: getVariantOptionValue(
                        variant?.selectedOptions || (product?.variants && product?.variants?.[0]?.selectedOptions),
                        colourOptionName
                      ),
                    },
                  ],
                },
              },
            }

        pushDataLayer(dataLayer)
      }
    },
    [checkout, decodeShopifyId, formatPrice, latestVersion, colourOptionName, pushDataLayer]
  )

  const trackProductView = useCallback(
    async (product, variant, parentCollection) => {
      const { collections, id, productType, title, vendor, images } = productNormaliser(product)
      if (title) {
        const dataLayer = latestVersion
          ? {
              event: "view_item",
              ecommerce: {
                currency: checkout?.currencyCode || "AUD",
                items: [
                  {
                    item_id: decodeShopifyId(id, "Product"),
                    item_name: title,
                    item_brand: vendor,
                    item_variant: variant?.title,
                    item_category: parentCollection || productType || collections?.[0]?.title,
                    quantity: 1,
                    price: Number(variant?.priceV2?.amount || variant?.priceV2 || (product?.variants && product?.variants?.[0]?.priceV2)),
                    image: images?.[0]?.src,
                  },
                ],
              },
            }
          : {
              event: "productDetail",
              ecommerce: {
                currency: checkout?.currencyCode || "AUD",
                detail: {
                  actionField: { list: "Product Page" }, // Product Page
                  products: [
                    {
                      id: decodeShopifyId(id, "Product"),
                      name: title,
                      brand: vendor,
                      category: parentCollection || collections?.[0]?.title || productType,
                      price: product?.variants && product?.variants[0]?.priceV2?.amount,
                      variant: getVariantOptionValue(
                        variant?.selectedOptions || (product?.variants && product?.variants?.[0]?.selectedOptions),
                        colourOptionName
                      ),
                    },
                  ],
                },
              },
            }
        trackProduct(product)
        pushDataLayer(dataLayer)
      }
    },
    [checkout, productNormaliser, decodeShopifyId, latestVersion, colourOptionName, pushDataLayer, trackProduct]
  )

  const trackCartUpdate = useCallback(
    async (type, variantId, quantity, lineitems) => {
      const selectedLineItem = lineitems?.filter(({ variant }: { variant: any }) => variant?.id === variantId)[0]

      if (selectedLineItem?.title) {
        const dataLayer = latestVersion
          ? {
              event: ["add", "change"].includes(type) ? "add_to_cart" : "remove_from_cart",
              ecommerce: {
                currency: checkout?.currencyCode || "AUD",
                items: [
                  {
                    item_id: decodeShopifyId(variantId, "ProductVariant"),
                    item_name: selectedLineItem?.title,
                    item_brand: selectedLineItem?.variant?.product?.vendor,
                    item_variant: selectedLineItem?.variant?.title,
                    item_category: selectedLineItem?.variant?.product?.productType,
                    price: formatPrice(selectedLineItem?.variant?.priceV2?.amount),
                    quantity,
                    image: selectedLineItem?.variant?.image?.originalSrc,
                  },
                ],
              },
            }
          : {
              event: ["add", "change"].includes(type) ? "addToCart" : "removeFromCart",
              ecommerce: {
                currency: checkout?.currencyCode || "AUD",
                [type]: {
                  products: [
                    {
                      id: decodeShopifyId(variantId, "ProductVariant"),
                      name: selectedLineItem?.title,
                      brand: selectedLineItem?.variant?.product?.vendor,
                      category: selectedLineItem?.variant?.product?.productType,
                      price: selectedLineItem?.variant?.priceV2?.amount,
                      quantity,
                      variant: getVariantOptionValue(selectedLineItem?.variant?.selectedOptions, colourOptionName),
                      dimension2: getVariantOptionValue(selectedLineItem?.variant?.selectedOptions, `Size`),
                      dimension3: selectedLineItem?.variant?.availableForSale ? `In Stock` : `Out of Stock`,
                    },
                  ],
                },
              },
            }

        pushDataLayer(dataLayer)
      }
    },
    [checkout, decodeShopifyId, formatPrice, latestVersion, colourOptionName, pushDataLayer]
  )

  const trackCartView = useCallback(async () => {
    if (checkout?.lineItems?.length) {
      if (latestVersion) {
        const dataLayer = {
          event: "view_cart",
          ecommerce: {
            currency: checkout?.currencyCode || "AUD",
            items: checkout?.lineItems?.map((lineitem: any) => ({
              item_id: decodeShopifyId(lineitem?.variant?.id, "ProductVariant"),
              item_name: lineitem?.title,
              item_brand: lineitem?.variant?.product?.vendor,
              item_variant: getVariantOptionValue(lineitem?.variant?.selectedOptions, colourOptionName),
              item_category: lineitem?.variant?.product?.productType,
              price: formatPrice(lineitem?.variant?.priceV2?.amount),
              quantity: lineitem?.quantity,
            })),
          },
        }
        pushDataLayer(dataLayer)
      }
    }
  }, [checkout, decodeShopifyId, formatPrice, latestVersion, colourOptionName, pushDataLayer])

  const trackWishlistUpdate = useCallback(
    async (type, product) => {
      if (product?.title) {
        if (latestVersion) {
          const dataLayer = {
            event: ["add"].includes(type) ? "add_to_wishlist" : "remove_from_wishlist",
            ecommerce: {
              currency: checkout?.currencyCode || "AUD",
              items: [
                {
                  item_id: decodeShopifyId(product?.selectedVariant?.id, "ProductVariant"),
                  item_name: product?.title,
                  item_brand: product?.vendor,
                  item_variant: getVariantOptionValue(product?.selectedVariant?.selectedOptions, colourOptionName),
                  item_category: product?.productType,
                  price: formatPrice(
                    product?.selectedVariant?.priceV2?.amount ?? product?.variants?.[0]?.priceV2?.amount ?? product?.variants?.[0]?.price
                  ),
                },
              ],
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [checkout, decodeShopifyId, formatPrice, latestVersion, colourOptionName, pushDataLayer]
  )

  const trackPromoImpression = useCallback(
    async ({ analyticsId, name, creative, position }) => {
      if (name) {
        const dataLayer = latestVersion
          ? {
              event: "view_promotion",
              ecommerce: {
                items: [
                  {
                    promotion_id: analyticsId,
                    promotion_name: name,
                    creative_name: creative,
                    creative_slot: position,
                  },
                ],
              },
            }
          : {
              event: "promotionView",
              ecommerce: {
                promoView: {
                  promotions: [{ id: analyticsId, name, creative, position }],
                },
              },
            }
        pushDataLayer(dataLayer)
      }
    },
    [latestVersion, pushDataLayer]
  )

  const trackPromoClick = useCallback(
    async ({ analyticsId, name, creative, position }) => {
      if (name) {
        const dataLayer = latestVersion
          ? {
              event: "select_promotion",
              ecommerce: {
                items: [
                  {
                    promotion_id: analyticsId,
                    promotion_name: name,
                    creative_name: creative,
                    creative_slot: position,
                  },
                ],
              },
            }
          : {
              event: "promotionClick",
              ecommerce: {
                promoClick: {
                  promotions: [{ id: analyticsId, name, creative, position }],
                },
              },
            }
        pushDataLayer(dataLayer)
      }
    },
    [latestVersion, pushDataLayer]
  )

  const trackLogin = useCallback(
    async method => {
      if (method) {
        if (latestVersion) {
          const dataLayer = {
            event: "login",
            ecommerce: {
              method: capitalise(method),
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [latestVersion, capitalise, pushDataLayer]
  )

  const trackSignup = useCallback(
    async method => {
      if (method) {
        if (latestVersion) {
          const dataLayer = {
            event: "sign_up",
            ecommerce: {
              method: capitalise(method),
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [latestVersion, capitalise, pushDataLayer]
  )

  const trackShare = useCallback(
    async (method, type, id) => {
      if (method) {
        if (latestVersion) {
          const dataLayer = {
            event: "share",
            ecommerce: {
              method: capitalise(method),
              content_type: type,
              content_id: id,
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [latestVersion, capitalise, pushDataLayer]
  )

  const trackClick = useCallback(
    async (type, id) => {
      if (type) {
        if (latestVersion) {
          const dataLayer = {
            event: "select_content",
            ecommerce: {
              content_type: type,
              content_id: id,
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [latestVersion, pushDataLayer]
  )

  const trackSearch = useCallback(
    async term => {
      if (term) {
        if (latestVersion) {
          const dataLayer = {
            event: "search",
            ecommerce: {
              search_term: term,
            },
          }
          pushDataLayer(dataLayer)
        }
      }
    },
    [latestVersion, pushDataLayer]
  )

  return {
    tracked,
    trackPageView,
    trackProductListView,
    trackProductImpression,
    trackProductView,
    trackProductClick,
    trackCartView,
    trackCartUpdate,
    trackWishlistUpdate,
    trackPromoImpression,
    trackPromoClick,
    trackLogin,
    trackSignup,
    trackShare,
    trackSearch,
    trackClick,
    decorateUrl,
    trackGDPRConsent,
  }
}
