import React, { useMemo } from "react"
import { AspectRatio, Box, ImageProps } from "@chakra-ui/react"
import { useShopify } from "@app/hooks/useShopify"
import { useMedia } from "@app/hooks/useMedia"
import Image from "@app/components/Image/Image"
export interface ProductCardPortaitProps {
  item: any
  isStl?: boolean
  fullSize: boolean
  showHoverImage?: boolean
}

const ProductCardPortait = ({ item, isStl, fullSize, showHoverImage }: ProductCardPortaitProps) => {
  const { imageSrcSets } = useShopify()
  const { isBase } = useMedia()

  const defaultImage = useMemo(() => item?.images?.[0]?.src, [item])
  const hoverImage = useMemo(
    () => (item?.images?.[1]?.src?.includes("_S_") || item?.images?.[1]?.src?.includes("_S.") ? null : item?.images?.[1]?.src),
    [item]
  )

  const imageProps: ImageProps = useMemo(
    () => ({
      alt: item?.title,
      objectFit: "cover",
      border: "1px",
      borderColor: "brand.divider",
      borderRadius: "sm",
      backgroundColor: "white",
      overflow: "hidden",
      w: fullSize ? "100%" : "205px",
      h: fullSize ? "100%" : "266px",
    }),
    [fullSize, item?.title]
  )

  const defaultSrcSet = useMemo(() => imageSrcSets(defaultImage, ""), [imageSrcSets, defaultImage])
  const hoverSrcSet = useMemo(() => imageSrcSets(hoverImage, ""), [imageSrcSets, hoverImage])
  const sizes = "(max-width: 740px) 50vw, 33vw"

  return (
    <Box mb={2} pos="relative">
      {fullSize ? (
        <AspectRatio ratio={isStl ? 1 : 82 / 107} mb={2}>
          <Box pos="relative">
            <Image {...imageProps} src={defaultImage} srcSet={defaultSrcSet} sizes={sizes} backgroundColor={`white`} overflow={`hidden`} />
            {hoverImage && !isBase && (
              <Image
                {...imageProps}
                src={hoverImage}
                srcSet={hoverSrcSet}
                sizes={sizes}
                backgroundColor={`white`}
                overflow={`hidden`}
                visibility={showHoverImage ? "visible" : "hidden"}
                pos="absolute"
              />
            )}
          </Box>
        </AspectRatio>
      ) : (
        <AspectRatio ratio={205 / 266} mb={2}>
          <Box pos="relative">
            <Image {...imageProps} loading="eager" src={defaultImage} />
            {hoverImage && !isBase && (
              <Image
                {...imageProps}
                loading="eager"
                src={hoverImage}
                visibility={showHoverImage ? "visible" : "hidden"}
                pos="absolute"
                inset={0}
              />
            )}
          </Box>
        </AspectRatio>
      )}
    </Box>
  )
}

export default React.memo(ProductCardPortait)
