import React from "react"
import { Box, HStack } from "@chakra-ui/react"

import AccountWidget from "@components/Account/Widget/AccountWidget"
import Brand from "@components/Brand"
import CartWidget from "@components/Cart/CartWidget"
import SearchWidget from "@components/Search/Widget/SearchWidget"
import WishlistWidget from "@components/Wishlist/WishlistWidget"

import NavigationHeaderWidget from "./NavigationHeaderWidget"
import NavigationHeader from "./NavigationHeader"
import Announcement from "@components/Announcement"

export interface NavigationHeaderStickyProps {
  menuActive: string | null
  setMenuActive: (key: string | null) => void
}

const NavigationHeaderSticky = ({ menuActive, setMenuActive }: NavigationHeaderStickyProps) => (
  <Box position="fixed" background="white" w="full" top={0} left={0} zIndex={10}>
    <Announcement />
    <HStack justify="space-between" px={2}>
      <HStack d={{ base: "flex", md: "none" }} spacing={0}>
        <NavigationHeaderWidget />
        <Brand />
      </HStack>
      <Box as="span" d={{ base: "none", md: "block" }}>
        <Brand desktopActive={true} />
      </Box>
      <NavigationHeader active={menuActive} setActive={setMenuActive} />
      <HStack>
        <SearchWidget />
        <AccountWidget />
        <WishlistWidget />
        <CartWidget />
      </HStack>
    </HStack>
  </Box>
)

export default React.memo(NavigationHeaderSticky)
