import React from "react"
import { Badge, Box, Flex } from "@chakra-ui/react"
import { ShopifyProduct } from "@usereactify/search/dist/types/shopify"

import useProductBadges from "@hooks/useProductBadges"

export interface ProductBadgesProps {
  tags: ShopifyProduct["tags"]
  showPlaceholder?: boolean
}

const ProductBadges = ({ tags, showPlaceholder }: ProductBadgesProps) => {
  const badges = useProductBadges(tags)

  return badges?.length > 0 ? (
    <Flex minH={"24px"} maxW="full" flexWrap={"wrap"} gap={1}>
      {badges?.map(({ label, props }, index) => (
        <React.Fragment key={index}>
          <Badge size="sm" {...props} width={"fit-content"} maxW={"full"} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            {label}
          </Badge>
        </React.Fragment>
      ))}
    </Flex>
  ) : showPlaceholder ? (
    <Box minH={"26px"} />
  ) : null
}

export default React.memo(ProductBadges)
